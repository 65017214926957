// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-linkedin-js": () => import("./../../../src/pages/linkedin.js" /* webpackChunkName: "component---src-pages-linkedin-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-department-js": () => import("./../../../src/templates/department.js" /* webpackChunkName: "component---src-templates-department-js" */),
  "component---src-templates-game-js": () => import("./../../../src/templates/game.js" /* webpackChunkName: "component---src-templates-game-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-patched-js": () => import("./../../../src/templates/patched.js" /* webpackChunkName: "component---src-templates-patched-js" */)
}

